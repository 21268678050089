<template>
  <a-row>
    <a-col :span="12">
      <a-spin :spinning="loading">
        <a-descriptions
          :column="1"
          class="simiot-descriptions simiot-descriptions-max-6"
        >
          <template slot="title">
            <a-popover title="说明">
              <template slot="content">
                <p>*可用额度=账户余额+信用额度-锁定金额</p>
                <p>*以下数据均统计有效数据</p>
                <p>*锁定金额为待审核项目金额</p>
              </template>
              <a-space>
                说明
                <a-icon type="question-circle" />
              </a-space>
            </a-popover>
          </template>

          <a-descriptions-item label="账户余额(元)">
            {{ data.balance | formatCurrency }}
          </a-descriptions-item>

          <a-descriptions-item label="信用额度(元)">
            {{ data.credit | formatCurrency }}
          </a-descriptions-item>

          <a-descriptions-item label="锁定金额(元)">
            {{ data.locked | formatCurrency }}
          </a-descriptions-item>

          <a-descriptions-item label="可用额度(元)">
            {{ data.available | formatCurrency }}
          </a-descriptions-item>
        </a-descriptions>
      </a-spin>
    </a-col>
  </a-row>
</template>

<script>
import { findAgentFinanceOverview } from '@/api/agent'

export default {
  name: 'AgentFinanceOverview',
  data() {
    return {
      data: {},
      loading: true
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findAgentFinanceOverview(this.agentId).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>

